import React from "react";
import PropTypes from "prop-types";
import Logo from "../../images/hutch.png";
import SmartText from "../../utils/TextHandler";
import SocialLinks from "../Global/SocialLinks";
import Link from "../Global/Link";
import Background from "../Global/Background";

const Footer = (props) => {
  const { settings } = props;

  return (
    <>
      <Background
        src={settings.background_links?.filename}
        sm="700x300"
        md="700x300"
        lg="1400x600"
        className="d-flex justify-items-center py-5"
      >
        <div className="container py-5">
          <div className="row justify-content-center align-items-center py-5">
            {settings.apps.map((item, idx) => {
              return (
                <div className="col-12 text-center col-md-auto py-3">
                  <a
                    href={item.link && item.link}
                    target="_blank"
                    className="brand-link-hover"
                  >
                    <img
                      src={item.image?.filename}
                      alt={item.title && item.title}
                      height="60"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </Background>
      <footer className="w-100 py-5 small brand-background-black brand-text-white">
        <div className="container-fluid">
          <div className="row align-items-center flex-column-reverse flex-md-row">
            <div className="col-12 col-md-6 text-start brand-footer-terms small">
              <div className="row">
                <div class="col-12 col-md-auto me-0 me-md-2 text-center text-md-start mb-3 mb-md-0">
                  <Link to="https://www.hutch.io/">
                    <img src={Logo} alt="Hutch Games" width="40" />
                  </Link>
                </div>
                <SmartText
                  content={settings.legal}
                  className="col text-center text-md-start"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 text-center text-md-end mb-5 mb-md-0">
              <SocialLinks
                className="brand-text-white m-0"
                itemClassName="mx-3 mx-lg-0 ms-lg-3 fs-2 mb-3"
                links={[
                  ["facebook", settings.facebook],
                  ["twitter", settings.twitter],
                  ["instagram", settings.instagram],
                ]}
              />
              <p className="small">
                <Link to="https://www.hutch.io/terms-of-service/">
                  Terms of Service
                </Link>
              </p>
              <p className="small">
                <Link to="https://www.hutch.io/privacy/">
                  Privacy and Cookies
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

Footer.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Footer.defaultProps = {
  settings: null,
};
